@use "setup.scss" as *;

body {
	overflow: hidden;
	background-color: $background;
	color: $on-background;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	letter-spacing: 0.5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

#wrapper {
	width: 36rem;
	height: 200%;
	position: relative;
	margin-top: 0%;
	transition: margin-top 0.25s;
}

.group {
	width: 100%;
	height: 50%;
	position: absolute;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	background-color: $surface-depth-1;
	overflow-y: auto;
}

#login {
	top: 0;
}

#signup {
	bottom: 0;
}

.content {
	flex: 0 1 100%;
	margin: 0 5rem;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

h2 {
	font-weight: lighter;
	font-size: 60px;
	letter-spacing: -0.5px;
}

form {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.error {
	background-color: $error;
	color: $on-error;
	font-size: 14px;
	border-radius: 4px;
	padding: 10px 15px;
	margin: 0.75rem 0;
	display: none;
}

.icon {
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.5rem;
}

.button-group {
	margin-top: 3rem;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

button {
	padding: 10px 15px;
	font-weight: bolder;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1.25px;
	line-height: 16px;
	border-radius: 4px;
	border: 0;
	outline: 0;
	margin: 15px 15px 15px 0;
	transition: all 0.25s;
	flex: 0 1 auto;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;

	&:hover {
		cursor: pointer;
	}
}

.submit-btn {
	color: $on-primary;
	background-color: $primary;

	&:hover {
		background-color: lighten($primary, 4%);
	}

	&:active {
		background-color: lighten($primary, 10%);
	}
}

.switch-btn {
	color: $on-surface;
	background-color: $surface-depth-4;

	&:hover {
		background-color: lighten($surface-depth-4, 4%);
	}

	&:active {
		background-color: lighten($surface-depth-4, 10%);
	}
}

input {
	background: transparent;
	color: $on-surface;
	border: 0;
	outline: 0;
	border-bottom: 1px solid $primary;
	font-size: 14px;
	letter-spacing: 0.25px;
	padding: 8px 0;
	margin: 10px 0;
	flex: 0 1 100%;
}

label {
	margin-top: 0.75rem;
}
