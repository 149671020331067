*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  overflow: hidden;
  background-color: #121212;
  color: whitesmoke;
  position: absolute;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

nav {
  flex: 0 1 auto;
  height: 100vh;
  width: 18rem;
  background-color: #1f1f1f;
  color: whitesmoke;
  letter-spacing: 0.75px;
  font-weight: 500;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
@media (max-width: 650px) {
  nav {
    width: auto;
  }
}
nav ul {
  list-style-type: none;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
nav li {
  width: 100%;
}
nav li:last-child {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: auto;
  padding: 0 1rem;
  width: 100%;
  height: 4rem;
}
nav li:last-child:hover {
  background-color: #333333;
}
nav li:last-child:active {
  background-color: #3d3d3d;
}
nav a {
  display: flex;
  align-items: center;
  color: whitesmoke;
  height: 4rem;
  width: 100%;
  padding: 0 1rem;
  text-decoration: none;
}
nav a:hover {
  background-color: #333333;
}
nav a:active {
  background-color: #3d3d3d;
}
@media (max-width: 650px) {
  nav .nav-text {
    display: none;
  }
}
nav img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}
@media (max-width: 650px) {
  nav img {
    margin-right: 0;
  }
}
nav .active a {
  color: whitesmoke;
  background-color: #770c08;
  font-weight: 600;
}
nav .active a:hover {
  background-color: #9d100b;
}
nav .active a:active {
  background-color: #b0120c;
}
nav #nav-toggle {
  float: left;
  width: 2rem;
  height: 2rem;
  margin: 1rem;
}
nav #nav-toggle:hover {
  cursor: pointer;
  margin: 0;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  background-color: #333333;
}

#main {
  flex: 0 1 auto;
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

body {
  overflow: hidden;
  background-color: #121212;
  color: whitesmoke;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#wrapper {
  width: 36rem;
  height: 200%;
  position: relative;
  margin-top: 0%;
  transition: margin-top 0.25s;
}

.group {
  width: 100%;
  height: 50%;
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #1f1f1f;
  overflow-y: auto;
}

#login {
  top: 0;
}

#signup {
  bottom: 0;
}

.content {
  flex: 0 1 100%;
  margin: 0 5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

h2 {
  font-weight: lighter;
  font-size: 60px;
  letter-spacing: -0.5px;
}

form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.error {
  background-color: #c85166;
  color: black;
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 15px;
  margin: 0.75rem 0;
  display: none;
}

.icon {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5rem;
}

.button-group {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

button {
  padding: 10px 15px;
  font-weight: bolder;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  line-height: 16px;
  border-radius: 4px;
  border: 0;
  outline: 0;
  margin: 15px 15px 15px 0;
  transition: all 0.25s;
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
button:hover {
  cursor: pointer;
}

.submit-btn {
  color: whitesmoke;
  background-color: #770c08;
}
.submit-btn:hover {
  background-color: #8a0e0a;
}
.submit-btn:active {
  background-color: #a7110c;
}

.switch-btn {
  color: whitesmoke;
  background-color: #292929;
}
.switch-btn:hover {
  background-color: #333333;
}
.switch-btn:active {
  background-color: #424242;
}

input {
  background: transparent;
  color: whitesmoke;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #770c08;
  font-size: 14px;
  letter-spacing: 0.25px;
  padding: 8px 0;
  margin: 10px 0;
  flex: 0 1 100%;
}

label {
  margin-top: 0.75rem;
}
/*# sourceMappingURL=login.02d98008.css.map */
