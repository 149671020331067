$background: hsl(0deg, 0%, 7%);

$surface: hsl(0deg, 0%, 7%);
$surface-depth-1: hsl(0deg, 0%, 12%);
$surface-depth-2: hsl(0deg, 0%, 14%);
$surface-depth-3: hsl(0deg, 0%, 15%);
$surface-depth-4: hsl(0deg, 0%, 16%);
$surface-depth-5: hsl(0deg, 0%, 18%);
$surface-depth-6: hsl(0deg, 0%, 19%);
$surface-depth-7: hsl(0deg, 0%, 21%);
$surface-depth-8: hsl(0deg, 0%, 22%);
$surface-depth-9: hsl(0deg, 0%, 23%);

$primary: hsl(2, 87%, 25%);

$secondary: hsl(2, 70%, 16%);

$error: hsl(349deg, 52%, 55%);

$on-background: hsl(0, 0%, 96%);
$on-surface: hsl(0, 0%, 96%);
$on-primary: hsl(0, 0%, 96%);
$on-secondary: hsl(0deg, 0%, 0%);
$on-error: hsl(0deg, 0%, 0%);

$disabled-outline: hsl(0deg, 0%, 11%);
$disabled-fill: hsl(0deg, 0%, 11%);
$disabled-content: hsl(0deg, 0%, 28%);

$media-toggle-width: 650px;

$border-radius: 0.5rem;

*, *::before, *::after {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
}

html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

body {
	overflow: hidden;
	background-color: $background;
	color: $on-background;
	position: absolute;
	font-family: 'Open Sans', sans-serif;
	letter-spacing: 0.5px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

nav {
	flex: 0 1 auto;
	height: 100vh;
	width: 18rem;
	background-color: $surface-depth-1;
	color: $on-surface;
	letter-spacing: 0.75px;
	font-weight: 500;
	z-index: 1;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	@media (max-width: $media-toggle-width) {
		width: auto;
	}

	ul {
		list-style-type: none;
		height: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
	}

	li {
		width: 100%;

		&:last-child {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			margin-top: auto;
			padding: 0 1rem;
			width: 100%;
			height: 4rem;

			&:hover {
				background-color: lighten($surface-depth-1, 8%);
			}
	
			&:active {
				background-color: lighten($surface-depth-1, 12%);
			}
		}
	}

	a {
		display: flex;
		align-items: center;
		color: $on-surface;
		height: 4rem;
		width: 100%;
		padding: 0 1rem;
		text-decoration: none;

		&:hover {
			background-color: lighten($surface-depth-1, 8%);
		}

		&:active {
			background-color: lighten($surface-depth-1, 12%);
		}
	}
	
	.nav-text {
		@media (max-width: $media-toggle-width) {
			display: none;
		}
	}

	img {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;

		@media (max-width: $media-toggle-width) {
			margin-right: 0;
		}
	}

	.active a {
		color: $on-primary;
		background-color: $primary;
		font-weight: 600;

		&:hover {
			background-color: lighten($primary, 8%);
		}

		&:active {
			background-color: lighten($primary, 12%);
		}
	}

	#nav-toggle {
		float: left;
		width: 2rem;
		height: 2rem;
		margin: 1rem;

		&:hover {
			cursor: pointer;
			margin: 0;
			padding: 1rem;
			width: 4rem;
			height: 4rem;
			background-color: lighten($surface-depth-1, 8%);
		}
	}
}

#main {
	flex: 0 1 auto;
	width: 100%;
	height: 100%;
	padding: 2rem;
	overflow-y: scroll;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}